/** @jsx jsx */
import { jsx, Box, Container, Flex } from 'theme-ui'
import {
  Hero,
  Overlay,
} from '@giraldomac/gatsby-theme-mindmerge/src/components/elements'
import BackgroundImage from 'gatsby-background-image'
import { RichText } from 'prismic-reactjs'

const HomeHero = ({ page }) => (
  <Hero id="intro">
    <BackgroundImage
      fluid={page.hero_imageSharp.childImageSharp.fluid}
      sx={{ py: 6 }}
    >
      <Container sx={{ px: '1em', position: 'relative', zIndex: 10 }}>
        <Flex sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ py: [4, 5], textAlign: 'center', width: 'full' }}>
            <Box
              sx={{
                'h2:after': {
                  backgroundColor: 'white',
                  margin: '2% auto',
                },
                p: {
                  fontFamily: 'serif',
                  fontSize: [4, 5],
                  lineHeight: 'snug',
                },
              }}
            >
              {RichText.render(page.hero_text)}
            </Box>
          </Box>
        </Flex>
      </Container>
      <Overlay />
    </BackgroundImage>
  </Hero>
)

export default HomeHero
