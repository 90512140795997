/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui'
import { RichText } from 'prismic-reactjs'
import { StaticQuery, graphql, Link } from 'gatsby'

const NewsListing = () => (
  <StaticQuery
    query={graphql`
      {
        prismic {
          allPosts(sortBy: date_DESC) {
            edges {
              node {
                title
                date
                content
                _linkType
                _meta {
                  id
                  uid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Flex
        as="ul"
        sx={{ flexWrap: 'wrap', listStyle: 'none', p: 0, mx: [0, 0, -3] }}
      >
        {data.prismic.allPosts.edges.map(({ node: post }) => (
          <Box
            as="li"
            key={post._meta.id}
            sx={{ px: [0, 0, 3], my: 3, width: ['full', 'full', '1/3'] }}
          >
            {post.date}
            <br />
            <strong>{RichText.asText(post.title)}</strong>
            <br />
            <Link to={`/news/${post._meta.uid}`}>Read More</Link>
          </Box>
        ))}
      </Flex>
    )}
  ></StaticQuery>
)

export default NewsListing
