/** @jsx jsx */
import { jsx, Box, Container, Flex } from 'theme-ui'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'

import {
  Layout,
  Main,
  Section,
} from '@giraldomac/gatsby-theme-mindmerge/src/components/layout'

import { HomeHero, NewsListing } from '../components/elements/'

const IndexPage = ({ data }) => {
  const image = `/metaimage.png`
  const page = data.prismic.home

  return (
    <Layout>
      <GatsbySeo
        title={page.meta_title}
        titleTemplate="%s | ExesaLibero Pharma"
        description={page.meta_decription}
        openGraph={{
          images: [
            {
              url: `${image}`,
              width: 800,
              height: 600,
              alt: 'Og Image Alt',
            },
          ],
        }}
      />

      <Main>
        {/* Hero */}
        <HomeHero page={page} />

        {/* Company */}
        <Box as="section" id="company" sx={{ pb: 0 }}>
          <Container sx={{ px: '1em' }}>
            <Flex
              sx={{
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ pb: 4, width: ['full'] }}>
                <h2 sx={{ mt: 5 }}>Company</h2>
                <h3>{page.company_heading}</h3>

                <p sx={{ fontSize: [2, 3] }}>
                  {RichText.asText(page.company_content)}
                </p>
              </Box>
            </Flex>
          </Container>

          <Box
            sx={{
              backgroundColor: 'light',
              background: `url(gplaypattern.png)`,
              py: 5,
            }}
          >
            <Container sx={{ px: '1em' }}>
              <Flex
                sx={{
                  flexWrap: 'wrap',
                  ul: { margin: 0, padding: 0 },
                  li: { listStyle: 'none', lineHeight: 'snug', my: 3 },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'gray.8',
                    boxShadow: 'xl',
                    color: 'white',
                    p: 4,
                    mb: 4,
                    width: ['full'],
                  }}
                >
                  <h4 sx={{ mt: 0, textAlign: ['center'] }}>Management</h4>

                  <Box
                    sx={{
                      ul: { display: 'flex', flexWrap: 'wrap' },
                      li: {
                        textAlign: ['center'],
                        width: ['full', '1/2'],
                      },
                    }}
                  >
                    {RichText.render(page.management)}
                  </Box>
                </Box>

                <Box sx={{ px: [0, 3], width: ['full', '1/2'] }}>
                  <h4>Board of Directors</h4>
                  {RichText.render(page.board_of_directors)}
                </Box>

                <Box sx={{ px: [0, 3], width: ['full', '1/2'] }}>
                  <h4>Scientific Advisory Board</h4>
                  {RichText.render(page.scientific_advisory_board)}
                </Box>
              </Flex>
            </Container>
          </Box>
        </Box>

        {/* Product & Technology */}
        <Section
          id="product"
          sx={{ backgroundColor: 'blue.6', color: 'white' }}
        >
          <Container sx={{ px: '1em' }}>
            <Flex sx={{ flexWrap: 'wrap' }}>
              <Box sx={{ width: 'full' }}>
                <h2 sx={{ mt: 4 }}>Product & Technology</h2>
                <h3>{page.product___technology_heading}</h3>
                <Box
                  sx={{
                    columnCount: [1, 1, 2],
                    columnGap: [null, null, 4],
                    'p:first-of-type': { mt: 0 },
                    '.block-img img': {
                      maxWidth: 'full',
                    },
                  }}
                >
                  {RichText.render(page.product___technology_content)}
                </Box>
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* Collaborations */}
        <Section
          id="collaborations"
          sx={{
            backgroundColor: 'light',
            background: `url(gplaypattern.png)`,
            py: 5,
          }}
        >
          <Container sx={{ px: '1em' }}>
            <Flex sx={{ flexWrap: 'wrap', mx: [0, 0, -3] }}>
              <Box sx={{ px: [0, 0, 3], width: ['full'] }}>
                <h2 sx={{ mt: 4 }}>Collaborations</h2>
              </Box>
              <Box sx={{ px: [0, 0, 3], width: ['full', 'full', '1/2'] }}>
                <h3>{page.collaborations_heading}</h3>
                {RichText.render(page.collaborations_content)}
              </Box>
              <Box sx={{ px: [0, 0, 3], width: ['full', 'full', '1/2'] }}>
                <Img
                  fluid={page.collaborations_imageSharp.childImageSharp.fluid}
                />
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* News & Events */}
        <Section id="news">
          <Container sx={{ px: '1em' }}>
            <Flex sx={{ flexWrap: 'wrap' }}>
              <Box sx={{ width: 'full' }}>
                <h2 sx={{ mt: 4 }}>News & Events</h2>
              </Box>

              <Box sx={{ width: 'full' }}>
                <NewsListing />
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* Contact */}
        <Section
          id="contact"
          sx={{ backgroundColor: 'blue.6', color: 'white' }}
        >
          <Container sx={{ px: '1em' }}>
            <Flex sx={{ flexWrap: 'wrap' }}>
              <Box sx={{ width: 'full' }}>
                <h2 sx={{ mt: 4 }}>Contact</h2>
                <h3>{page.contact_heading}</h3>
                {RichText.render(page.contact_content)}
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    prismic {
      home(lang: "en-us", uid: "home") {
        title
        hero_text
        hero_image
        company_heading
        company_content
        management
        board_of_directors
        scientific_advisory_board
        product___technology_heading
        product___technology_content
        collaborations_heading
        collaborations_content
        collaborations_image
        collaborations_imageSharp {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contact_heading
        contact_content
        meta_title
        meta_description
        _linkType
        hero_imageSharp {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
